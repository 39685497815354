import { toast } from "react-toastify";
import constantsValues from "../constants/constantsValues";
import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";

export default async function switchNetwork(metamaskProvider, chainId) {
  try {
    await metamaskProvider.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: chainId }],
    });
  } catch (error) {
    console.log(error);
    if (error.code === 4902) {
      toast.error(`Please Add  ${constantsValues[chainId].ChainName}\n`);
      try {
        if (chainId === "0x13881") {
          await metamaskProvider.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: "0x13881",
                chainName: "Mumbai",
                nativeCurrency: {
                  name: "MATIC",
                  symbol: "MATIC",
                  decimals: 18,
                },
                blockExplorerUrls: ["https://mumbai.polygonscan.com"],
                rpcUrls: ["https://rpc-mumbai.maticvigil.com"],
              },
            ],
          });
        } else if (chainId === "0xc45") {
          await metamaskProvider.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: "0xc45",
                chainName: "Filecoin - Hyperspace testnet",
                nativeCurrency: {
                  name: "tFIL",
                  symbol: "tFIL",
                  decimals: 18,
                },
                blockExplorerUrls: ["https://goerli.explorer.zksync.io"],
                rpcUrls: ["https://filecoin-hyperspace.chainup.net/rpc/v1"],
              },
            ],
          });
        } else if (chainId === "0x89") {
          await metamaskProvider.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: "0x89",
                chainName: "Polygon Mainnet",
                nativeCurrency: {
                  name: "MATIC",
                  symbol: "MATIC",
                  decimals: 18,
                },
                blockExplorerUrls: ["https://polygonscan.com/"],
                rpcUrls: ["https://polygon-rpc.com/"],
              },
            ],
          });
        } else if (chainId === "0x51") {
          await metamaskProvider.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: "0x51",
                chainName: "Shibuya Testnet",
                nativeCurrency: {
                  name: "SBY",
                  symbol: "SBY",
                  decimals: 18,
                },
                blockExplorerUrls: ["https://blockscout.com/shibuya"],
                rpcUrls: ["https://evm.shibuya.astar.network"],
              },
            ],
          });
        } else if (chainId === "0x5fd8b025973e3") {
          await metamaskProvider.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: "0x5fd8b025973e3",
                chainName: "lyncworld",
                nativeCurrency: {
                  name: "mpn",
                  symbol: "mpn",
                  decimals: 18,
                },
                blockExplorerUrls: ["https://lyncworld-1686148365251555-1.sp1.sagaexplorer.io/"],
                rpcUrls: ["https://lyncworld-1686148365251555-1.jsonrpc.sp1.sagarpc.io"],
              },
            ],
          });
        } else if (chainId === "0x1") {
          await metamaskProvider.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: "0x1",
                chainName: "Ethereum mainnet",
                nativeCurrency: {
                  name: "ETH",
                  symbol: "ETH",
                  decimals: 18,
                },
                blockExplorerUrls: ["https://etherscan.io"],
                rpcUrls: ["https://mainnet.infura.io/v3/"],
              },
            ],
          });
        } else if (chainId === "0x5") {
          await metamaskProvider.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: "0x5",
                chainName: "Goerli",
                nativeCurrency: {
                  name: "ETH",
                  symbol: "ETH",
                  decimals: 18,
                },
                blockExplorerUrls: ["https://goerli.etherscan.io"],
                rpcUrls: ["https://rpc.ankr.com/eth_goerli"],
              },
            ],
          });
        } else if (chainId === "0x250") {
          await metamaskProvider.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: "0x250",
                chainName: "Astar",
                nativeCurrency: {
                  name: "ASTR",
                  symbol: "ASTR",
                  decimals: 18,
                },
                blockExplorerUrls: ["https://astar.subscan.io"],
                rpcUrls: ["https://evm.astar.network"],
              },
            ],
          });
        } else if (chainId === "0x144") {
          await metamaskProvider.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: "0x144",
                chainName: "zkSync Era Mainnet",
                nativeCurrency: {
                  name: "ETH",
                  symbol: "ETH",
                  decimals: 18,
                },
                blockExplorerUrls: ["https://explorer.zksync.io"],
                rpcUrls: ["https://mainnet.era.zksync.io"],
              },
            ],
          });
        } else if (chainId === "0x118") {
          await metamaskProvider.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: "0x118",
                chainName: "zkSync Era Testnet",
                nativeCurrency: {
                  name: "ETH",
                  symbol: "ETH",
                  decimals: 18,
                },
                blockExplorerUrls: ["https://goerli.explorer.zksync.io"],
                rpcUrls: ["https://testnet.era.zksync.dev"],
              },
            ],
          });
        } else if (chainId === "0x1389") {
          await metamaskProvider.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: "0x1389",
                chainName: "Mantle Testnet",
                nativeCurrency: {
                  name: "BIT",
                  symbol: "BIT",
                  decimals: 18,
                },
                blockExplorerUrls: ["https://explorer.testnet.mantle.xyz"],
                rpcUrls: ["https://rpc.testnet.mantle.xyz"],
              },
            ],
          });
        } else if (chainId === "0x1388") {
          await metamaskProvider.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: "0x1388",
                chainName: "Mantle",
                nativeCurrency: {
                  name: "BIT",
                  symbol: "BIT",
                  decimals: 18,
                },
                blockExplorerUrls: ["https://explorer.mantle.xyz"],
                rpcUrls: ["https://rpc.mantle.xyz"],
              },
            ],
          });
        } else if (chainId === "0xA4B1") {
          await metamaskProvider.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: "0xA4B1",
                chainName: "Arbitrum One",
                nativeCurrency: {
                  name: "ETH",
                  symbol: "ETH",
                  decimals: 18,
                },
                blockExplorerUrls: ["https://explorer.arbitrum.io"],
                rpcUrls: ["https://arbitrum-mainnet.infura.io"],
              },
            ],
          });
        } else if (chainId === "0x66eed") {
          await metamaskProvider.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: "0x66eed",
                chainName: "Arbitrum Goerli Testnet",
                nativeCurrency: {
                  name: "ETH",
                  symbol: "ETH",
                  decimals: 18,
                },
                blockExplorerUrls: ["https://goerli.arbiscan.io/"],
                rpcUrls: ["https://goerli-rollup.arbitrum.io/rpc"],
              },
            ],
          });
        } else if (chainId === "0x42") {
          await metamaskProvider.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: "0x42",
                chainName: "OKXChain Mainnet",
                nativeCurrency: {
                  name: "OKT",
                  symbol: "OKT",
                  decimals: 18,
                },
                blockExplorerUrls: ["https://www.oklink.com/en/okc"],
                rpcUrls: ["https://exchainrpc.okex.org"],
              },
            ],
          });
        } else if (chainId === "0xA86A") {
          await metamaskProvider.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: "0xA86A",
                chainName: "Avalanche C-Chain",
                nativeCurrency: {
                  name: "AVAX",
                  symbol: "AVAX",
                  decimals: 18,
                },
                blockExplorerUrls: ["https://snowtrace.io"],
                rpcUrls: ["https://api.avax.network/ext/bc/C/rpc"],
              },
            ],
          });
        } else if (chainId === "0xA869") {
          await metamaskProvider.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: "0xA869",
                chainName: "Avalanche Fuji Testnet",
                nativeCurrency: {
                  name: "AVAX",
                  symbol: "AVAX",
                  decimals: 18,
                },
                blockExplorerUrls: ["https://testnet.snowtrace.io"],
                rpcUrls: ["https://api.avax-test.network/ext/bc/C/rpc"],
              },
            ],
          });
        } else if (chainId === "0x128") {
          await metamaskProvider.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: "0x128",
                chainName: "Hedera Testnet",
                nativeCurrency: {
                  name: "HBAR",
                  symbol: "HBAR",
                  decimals: 18,
                },
                blockExplorerUrls: ["https://hashscan.io/testnet/"],
                rpcUrls: ["https://testnet.hashio.io/api"],
              },
            ],
          });
        } else if (chainId === "0x41") {
          await metamaskProvider.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: "0x41",
                chainName: "OKExChain Testnet",
                nativeCurrency: {
                  name: "OKT",
                  symbol: "OKT",
                  decimals: 18,
                },
                blockExplorerUrls: ["https://www.oklink.com/okexchain-test"],
                rpcUrls: ["https://exchaintestrpc.okex.org"],
              },
            ],
          });
        } else if (chainId === "0x5a2") {
          await metamaskProvider.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: "0x5a2",
                chainName: "Polygon zkEVM Testnet",
                nativeCurrency: {
                  name: "ETH",
                  symbol: "ETH",
                  decimals: 18,
                },
                blockExplorerUrls: ["https://explorer.public.zkevm-test.net"],
                rpcUrls: ["https://rpc.public.zkevm-test.net"],
              },
            ],
          });
        } else if (chainId === "0x15eb") {
          await metamaskProvider.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: "0x15eb",
                chainName: "opBNB Testnet",
                nativeCurrency: {
                  name: "tBNB",
                  symbol: "tBNB",
                  decimals: 18,
                },
                blockExplorerUrls: ["https://opbnb-testnet.bscscan.com"],
                rpcUrls: ["https://opbnb-testnet-rpc.bnbchain.org"],
              },
            ],
          });
        } else if (chainId === "0x61") {
          await metamaskProvider.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: "0x61",
                chainName: "BNB Smart Chain Testnet",
                nativeCurrency: {
                  name: "tBNB",
                  symbol: "tBNB",
                  decimals: 18,
                },
                blockExplorerUrls: ["https://testnet.bscscan.com"],
                rpcUrls: ["https://bsc-testnet.publicnode.com"],
              },
            ],
          });
        } else if (chainId === "0x14a34") {
          await metamaskProvider.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: "0x14a34",
                chainName: "Base Sepolia Testnet",
                nativeCurrency: {
                  name: "ETH",
                  symbol: "ETH",
                  decimals: 18,
                },
                blockExplorerUrls: ["https://sepolia.basescan.org/"],
                rpcUrls: ["https://sepolia.base.org"],
              },
            ],
          });
        } else if (chainId === "0x2105") {
          await metamaskProvider.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: "0x2105",
                chainName: "Base",
                nativeCurrency: {
                  name: "ETH",
                  symbol: "ETH",
                  decimals: 18,
                },
                blockExplorerUrls: ["https://basescan.org/"],
                rpcUrls: ["https://mainnet.base.org"],
              },
            ],
          });
        } else if (chainId === "0x13882") {
          await metamaskProvider.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: "0x13882",
                chainName: "Polygon Amoy",
                nativeCurrency: {
                  name: "MATIC",
                  symbol: "MATIC",
                  decimals: 18,
                },
                blockExplorerUrls: ["https://amoy.polygonscan.com/"],
                rpcUrls: ["https://rpc-amoy.polygon.technology/"],
              },
            ],
          });
        } else if (chainId === "0xAA36A7") {
          await metamaskProvider.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: "0xAA36A7",
                chainName: "Sepolia Testnet",
                nativeCurrency: {
                  name: "ETH",
                  symbol: "ETH",
                  decimals: 18,
                },
                blockExplorerUrls: ["https://sepolia.etherscan.io/"],
                rpcUrls: ["https://ethereum-sepolia-rpc.publicnode.com/"],
              },
            ],
          });
        }
        window.location.reload();
        return;
      } catch (error) {
        console.log("error", error);
        let errordata = error.data ? error.data.message.split(":")[1] : error.message.split(":")[1];
        console.log("Error:", errordata);
        return;
      }
    }
    throw error;
  }
}
