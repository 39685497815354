import { createAsGAFunctions } from "../../GA/CreateAsGA";

export function LaunchGame() {
  return (
    <>
      <div className="launch-game content-components">
        <div className="image-cards-container">
          <div className="image-cards">
            <span className="image-container bg"></span>
          </div>
          <div className="image-cards">
            <span className="image-container bg"></span>
          </div>
          <div className="image-cards">
            <span className="image-container"></span>
          </div>
          <div className="image-cards">
            <span className="image-container last-container"></span>
          </div>
        </div>
        <div className="content-container">
          <h1 className="content-heading">Launch Web3 Games</h1>
          <div className="content-info">
            <p className="info">
              Deploy your entire game or metaverse on IPFS and list it as NFT
            </p>
            <a href="https://portal.lync.world/#/" target="_blank">
              <button
                className="btn"
                onClick={() => createAsGAFunctions.knowMoreEvent()}
              >
                Deploy Now
              </button>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
