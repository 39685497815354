import EncryptApiKey from "./EncryptApiKey";

async function checkWhiteListUser(contractAddress, email) {
  const encryptedKey = await EncryptApiKey();
  const res = await fetch(
    process.env.REACT_APP_SERVER_URL +
      `white-list-user/get-by-address?contractAddress=${contractAddress}&userAddress=${email.toLowerCase()}`,
    { headers: { "x-api-key": encryptedKey } }
  );
  const resData = await res.json();
  if (res.status == 200) {
    if (resData.msg === "All records found") {
      return;
    }
  } else {
    throw Error(resData.msg);
  }
}
export default checkWhiteListUser;
