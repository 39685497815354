export const ChooseRecipient = ({
    airdropActionHandler,
    myselfActionHandler,
}) => {
    return (
        <>
            <span className="choose-recipient-title">Choose Recipient(s)</span>
            <span className="choose-recipient-msg">
                On the next step, You will select the number of editions.
            </span>
            <div className="choose-recipient-actions">
                <button className="action-btn" onClick={airdropActionHandler}>
                    Airdrop
                </button>
                <button className="action-btn" onClick={myselfActionHandler}>
                    Myself
                </button>
            </div>
        </>
    );
};
