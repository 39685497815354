export default function (
  category,
  contractType,
  name,
  symbol,
  uri,
  maxSupply,
  biconomyKey,
  maxMintAllowedPerUser,
  cost,
  title,
  description,
  image
) {
  const specialCharsReg = new RegExp(
    /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
  );
  if (specialCharsReg.test(name))
    throw Error("Contract Name can't contain special characters or spaces.");

  if (specialCharsReg.test(symbol))
    throw Error("Contract Symbol can't contain special characters or spaces.");
  if (!name || !symbol) {
    throw Error("Please fill all the information");
  } else if (name.includes(" ") || symbol.includes(" ")) {
    throw Error("Contract Name or Contract Symbol can't contain spaces.");
  } else if (category === "collection") {
    if (!uri || !maxSupply || !maxMintAllowedPerUser) {
      throw Error(
        "Please Fill Toekn URI and Max. Supply and Max. Mint Allowed per."
      );
    } else if (!image) throw Error("Please Upload Image");
    else if (!title || !description) {
      throw Error("Title and Description are required");
    } else if (uri.includes(" ") || maxSupply.includes(" ")) {
      throw Error("Token Uri, Max. supply can't contain spaces.");
    } else if (!Number(maxSupply) || maxSupply < 0) {
      throw Error("Max. Supply Must be Number and Greater then 0");
    } else if (!Number(maxMintAllowedPerUser) || maxMintAllowedPerUser < 0) {
      throw Error(
        "Max. Mint Allowed per. User Must be Number and Greater then 0"
      );
    } else if (contractType === "biconomy") {
      if (!biconomyKey || biconomyKey.includes(" ")) {
        throw Error("Please provide biconomy key without space");
      }
    } else if (
      contractType === "ERC721ACollection" ||
      contractType === "ERC1155Collection"
    ) {
      if (!cost) {
        throw Error("Please Fill Cost");
      } else if (cost < 0) {
        throw Error("Cost Must be Number and Greater then 0");
      }
    }
  }
}
