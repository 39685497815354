import { appImages } from "../constants/images";

const constantsValues = {
  "0x1": {
    chainID: "0x1",
    blockExplorer: "https://etherscan.io/address/",
    ChainName: "Ethereum",
    slug: "Ethereum",
    testnet: "false",
    rpcURL: "https://ethereum-rpc.publicnode.com/",
    logo: appImages.ethMainnetLogo,
  },
  "0x2105": {
    chainID: "0x2105",
    blockExplorer: "https://basescan.org/address/",
    ChainName: "Base",
    slug: "Base",
    testnet: "false",
    rpcURL: "https://mainnet.base.org/",
    logo: appImages.baseLogo,
  },
  "0x89": {
    chainID: "0x89",
    blockExplorer: "https://polygonscan.com/address/",
    ChainName: "Polygon",
    slug: "Polygon",
    testnet: "false",
    rpcURL: "https://polygon.drpc.org/",
    logo: appImages.polygonLogo,
  },
  "0xAA36A7": {
    chainID: "0xAA36A7",
    blockExplorer: "https://sepolia.etherscan.io/address/",
    ChainName: "Sepolia",
    slug: "Sepolia",
    testnet: "true",
    rpcURL: "https://ethereum-sepolia-rpc.publicnode.com/",
    logo: appImages.ethGoerliLogo,
  },
  "0x14a34": {
    chainID: "0x14a34",
    blockExplorer: "https://sepolia.basescan.org/address/",
    ChainName: "Base Sepolia",
    slug: "BaseSepolia",
    testnet: "true",
    rpcURL: "https://sepolia.base.org/",
    logo: appImages.baseLogo,
  },
  "0x13882": {
    chainID: "0x13882",
    blockExplorer: "https://amoy.polygonscan.com/address/",
    ChainName: "Ploygon Amoy",
    slug: "PloygonAmoy",
    testnet: "true",
    rpcURL: "https://rpc-amoy.polygon.technology/",
    logo: appImages.polygonLogo,
  },
};

const defautlChainId = "0x2105";
export { defautlChainId };
export default constantsValues;
