import { useNavigate } from "react-router-dom";
import { appImages } from "../../constants/images";
import { createAsGAFunctions } from "../../GA/CreateAsGA";

function Cards() {
  const cardsArray = [];

  for (let i = 0; i < 5; i++) {
    cardsArray.push(
      <div className="cards">
        <span className="image-container">
          <img src={appImages.card} alt="card" />
        </span>
      </div>
    );
  }

  return cardsArray && cardsArray.length > 0
    ? cardsArray.map((card) => card)
    : null;
}

export function MultipleEditions() {
  const navigate = useNavigate();

  return (
    <>
      <div className="multiple-editions content-components">
        <div className="cards-container">
          <Cards />
        </div>
        <div className="content-container">
          <h1 className="content-heading">Multiple Editions</h1>
          <div className="content-info">
            <p className="info">
              Create multiple editions of your art by deploying your own
              <strong> ERC 1155 </strong>contract
            </p>
            <button
              className="btn"
              onClick={() => {
                createAsGAFunctions.getStartedEvent();
                navigate("/create-contract", {
                  state: "multiple",
                });
              }}
            >
              Get Started
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
