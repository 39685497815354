const ERC721ABI = require("../abis/ERC721.json");
const ERC1155ABI = require("../abis/ERC1155.json");
const ERC721AABI = require("../abis/ERC721A.json");
const ERC721ACollectionABI = require("../abis/ERC721ACollection.json");
const ERC1155CollectionABI = require("../abis/ERC1155Collection.json");
const biconomyABI = require("../abis/biconomy.json");
const biconomy1155ABI = require("../abis/biconomy1155.json");

export default async function useGetABI(contractType) {
  // if else based on contract type
  if (contractType === "ERC721") {
    return ERC721ABI;
  } else if (contractType === "ERC721A") {
    return ERC721AABI;
  } else if (contractType === "ERC1155") {
    return ERC1155ABI;
  } else if (contractType === "ERC721ACollection") {
    return ERC721ACollectionABI;
  } else if (contractType === "ERC1155Collection") {
    return ERC1155CollectionABI;
  } else if (contractType === "biconomy") {
    return biconomyABI;
  } else if (contractType === "biconomy1155") {
    return biconomy1155ABI;
  }
}
