import { useContext, useEffect, useReducer, useState } from "react";
import ReactGA from "react-ga4";
import { useNavigate } from "react-router-dom";
import { deployGAFunctions } from "../../GA/DeployGA";
import Loader from "../../components/Loader";
import { APIRoutes } from "../../constants/ApiRoutes";
import constantsValues from "../../constants/constantsValues";
import { AuthContext } from "../../context/AuthContext";
import ApiCall from "../../helper/ApiCall";
import GetContractType from "../../helper/GetContractType";
export default function DeployContract() {
  const initialState = { isLoading: true, listOfContract: [] };
  const { address, chainToConnect } = useContext(AuthContext);
  const [filterChain, setFilterChain] = useState("");
  const [contractData, dispatch] = useReducer(reducer, initialState);
  const { listOfContract, isLoading } = contractData;
  //
  const navigate = useNavigate();
  //
  const smartContractDetails = (data) => {
    ReactGA.event({
      action: "Smart Contract Details",
      category: "Details",
      label: [data.networkType, data.contractAddress, data.contractName],
    });
  };

  async function fetchListOfContract() {
    if (!address) {
      dispatch({ type: "LoadingFalse" });
      return;
    }
    dispatch({ type: "LoadingTrue" });
    try {
      const { res, resData } = await ApiCall(APIRoutes.getContracts, "POST", {
        walletAddress: address,
        chainId: filterChain,
      });
      if (!res.ok) throw new Error(res.status + " " + res.statusText);
      if (resData.success) {
        dispatch({ type: "setListOfContract", payload: resData.data });
      }
    } catch (err) {
      console.log("Error in fetching list of contracts: ", err);
      dispatch({ type: "LoadingFalse" });
      return;
    }
  }

  useEffect(() => {
    fetchListOfContract();
  }, [address, chainToConnect, filterChain]);

  return (
    <div className="deploy-contract">
      <main className="main-content">
        <div className="heading-container">
          <h1 className="page-heading">Deploy a Contract</h1>
          <p className="page-info">
            Select a Smart Contract or deploy a new one to create your unique
            NFTs.
          </p>
        </div>
        <section className="contracts-section">
          <div className="contracts-heading">
            <h2 className="heading-text">Your Contracts</h2>
            <div className="heading-actions-container">
              <div className="filter-chain-optionGroup">
                <label htmlFor="chain" className="filter-chain-label">
                  Chain
                </label>
                <select
                  className="filter-chain-name"
                  id="chain"
                  name="chain"
                  // value={chainToConnect[filterChain]?.ChainName} // @todo @arpit29joshi
                  onChange={(e) => {
                    setFilterChain(e.target.value);
                  }}
                >
                  <option value="">All</option>
                  {Object.keys(constantsValues).map((keyName, i) => (
                    <option value={keyName} key={i}>
                      {constantsValues[keyName].ChainName}
                    </option>
                  ))}
                </select>
              </div>
              <button
                className="new-contract-btn"
                onClick={() => {
                  deployGAFunctions.newContractEvent();
                  navigate("/create-contract");
                }}
              >
                New Contract
              </button>
            </div>
          </div>
          {!isLoading ? (
            <>
              {listOfContract.length > 0 ? (
                <div className="contracts-conatiner">
                  {listOfContract.map((data, i) => {
                    return (
                      <div
                        className="contract-card"
                        key={i + data?._id}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          smartContractDetails(data);
                          navigate(`/contract/${data.contractAddress}`, {
                            state: data,
                          });
                        }}
                      >
                        <h3 className="contract-name contract-info">
                          {data?.contractName}
                        </h3>
                        <div className="chain contract-info">
                          <p className="label">Chain Name</p>
                          <p className="name">{data?.networkType}</p>
                        </div>
                        <div className="hr"></div>
                        <div className="type contract-info">
                          <p className="label">Type</p>
                          <p className="name">
                            {GetContractType(data?.category)}
                          </p>
                        </div>
                        <div className="symbol contract-info">
                          <p className="label">Symbol</p>
                          <p className="name">{data?.symbol}</p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="no-data-msg-container">
                  <p className="msg-heading">
                    It seems you haven&apos;t deployed any contract yet!
                  </p>
                  <button
                    className="deploy-contract-btn"
                    onClick={() => navigate("/create-contract")}
                  >
                    Deploy a Contract Now
                  </button>
                </div>
              )}
            </>
          ) : (
            <div className="loader-position">
              <Loader />
            </div>
          )}
        </section>
      </main>
    </div>
  );
}

function reducer(state, action) {
  switch (action.type) {
    case "LoadingFalse": {
      return { isLoading: false, listOfContract: [] };
    }

    case "LoadingTrue": {
      return { isLoading: true, listOfContract: [] };
    }

    case "setListOfContract": {
      return { listOfContract: action.payload, isLoading: false };
    }

    default: {
      return { ...state };
    }
  }
}
