import ReactGA from "react-ga4";

export const claimNFTGAFunctions = {
  claimNFTEvent: function () {
    ReactGA.event({
      action: "Claim NFT",
      category: "Claim",
    });
  },
  verifyEmailEvent: function () {
    ReactGA.event({
      action: "Verify Email",
      category: "Claim",
    });
  },
};
