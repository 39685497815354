import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { useNavigate } from "react-router-dom";
import { createAsGAFunctions } from "../../GA/CreateAsGA";

export function CreateNFT() {
  const [imageFile, setImageFile] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const navigate = useNavigate();
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];
      const url = URL.createObjectURL(file);

      setImageFile(file);
      setImageUrl(url);
    },
  });

  return (
    <div className="create-nft content-components">
      <div className="nft-input-container"></div>
      <div className="content-container">
        <h1 className="content-heading">List 1/1 art</h1>
        <div className="content-info">
          <p className="info">
            Showcase your unique artwork on the blockchain by deploying your
            <strong> ERC721 </strong> contract
          </p>
          <button
            className="btn"
            onClick={() => {
              createAsGAFunctions.getStartedEvent();
              navigate("/create-contract", { state: "oneOnone" });
            }}
          >
            Get Started
          </button>
        </div>
      </div>
    </div>
  );
}
