const getContracts = "get-contracts";
const getWhiteListUser = "white-list-user/get?contractAddress=";
const addWhiteListUser = "white-list-user/insert";
const compileContract = "compile-contract";
const verifyContract = "verify-contract";
const getClamierDetails = "get-clamier-details";
const updatWhiteListUser = "white-list-user/update";
const getBiconomyContracts = "get-biconomy-contracts";
const getFundingStatus = "get-funding-status";
const insertFundingStatus = "insert-funding-status";
const s3Upload="s3/upload"

export const APIRoutes = {
  getContracts,
  getWhiteListUser,
  addWhiteListUser,
  compileContract,
  verifyContract,
  getClamierDetails,
  updatWhiteListUser,
  getBiconomyContracts,
  getFundingStatus,
  insertFundingStatus,
  s3Upload
};
