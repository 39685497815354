function MetaMaskError(err) {
  const message = err.toLowerCase();
  if (message.includes("user rejected transaction")) {
    return "User Rejected Transaction";
  } else if (
    message.includes("insufficient") ||
    message.includes("insufficient funds")
  ) {
    return "Insufficient Funds";
  } else if (message.includes("invalid input")) {
    return "Please provide a valid Input";
  } else if (
    message.includes("gas limit") ||
    message.includes("gas limit exceeded")
  ) {
    return "Gas Limit Exceeded";
  } else if (message.includes("contract-specific errors")) {
    return "Contract specific errors";
  } else if (message.includes("network congestion")) {
    return "Network is congested, Please try again later!!";
  } else if (message.includes("message network does not support ens")) {
    return "We does not support ENS";
  } else if (message.includes("internal json-rpc error")) {
    return "Internal JSON-RPC error occurred, make sure you have enough funds in your account";
  } else if (message.includes("underlying network changed")) {
    return "Please Connect to the correct network or refresh and try again!!";
  } else {
    return "Something went wrong, Please try again later!!";
  }
}
export default MetaMaskError;
