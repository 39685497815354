import { APIRoutes } from "../constants/ApiRoutes";
import ApiCall from "./ApiCall";

async function UpdateWhiteListUser(contractAddress, email, mintCount) {
  const { res, resData } = await ApiCall(APIRoutes.updatWhiteListUser, "POST", {
    contractAddress: contractAddress,
    userAddress: email.toLowerCase(),
    mintCount: mintCount,
  });
  if (res.ok) {
    return;
  } else {
    throw Error(resData.msg);
  }
}
export default UpdateWhiteListUser;
