import { createContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ethers } from "ethers";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import switchNetwork from "../helper/switchNetwork";
import { APIRoutes } from "../constants/ApiRoutes";
import ApiCall from "../helper/ApiCall";
export const MintContext = createContext();
export const MintContextProvider = ({ children }) => {
  let chainId;
  const navigate = useNavigate();
  const { contractAddress, chainName } = useParams();
  const [metamaskprovider, setMetamaskProvider] = useState(null);
  const [ethersProvider, setEthersProvider] = useState(null);

  const [walletAddress, setWalletAddress] = useState(null);
  const [claimerDetails, setClaimerDetails] = useState({
    title: "",
    description: "",
    image: "",
    category: "",
    chainId: "",
    isWhiteListUser: "",
  });
  const checkIfWalletIsConnected = async () => {
    const { ethereum } = window;
    let metamaskProvider;

    if (!ethereum) {
      toast.error(
        "You must install Metamask into your browser: https://metamask.io/download.html"
      );

      console.log("METAMASK NOT DETECTED");
      // USE TOASTER
      // return;
    }
    if (window.ethereum) {
      if (window.ethereum.providers === undefined) {
        metamaskProvider = window.ethereum;
      } else {
        metamaskProvider = window.ethereum.providers.find(
          (provider) => provider.isMetaMask
        );
      }
      const userProvider = new ethers.providers.Web3Provider(metamaskProvider);
      setEthersProvider(userProvider);
      setMetamaskProvider(metamaskProvider);
    }
    const { res: response, resData: responseData } = await ApiCall(
      APIRoutes.getClamierDetails,
      "POST",
      {
        contractAddress: contractAddress.toLowerCase(),
        networkType: chainName,
      }
    );
    if (responseData.statusCode == 200 && responseData.data.length > 0) {
      if (responseData.data[0].category != "collection") {
        toast.error("Contract Is Not Collection Type");
        setTimeout(() => {
          navigate("/");
        }, [2500]);
        return;
      } else {
        setClaimerDetails({
          category: responseData.data[0].category,
          contractType: responseData.data[0].contractType,
          title: responseData.data[0].title,
          description: responseData.data[0].description,
          image: responseData.data[0].image,
          chainId: responseData.data[0].chainId,
          isWhiteListUser: responseData.data[0].isWhiteListUser,
        });
        if (metamaskProvider) {
          chainId = await metamaskProvider.request({ method: "eth_chainId" });

          ethereum.on("chainChanged", async (newChainId) => {
            if (newChainId != responseData.data[0].chainId) {
              await switchNetwork(
                metamaskProvider,
                responseData.data[0].chainId
              );
            }
          });
          metamaskProvider.on("accountsChanged", async () => {
            window.location.reload();
          });

          const accounts = await metamaskProvider.request({
            method: "eth_accounts",
          });
          if (accounts && accounts.length > 0) {
            if (chainId !== responseData.data[0].chainId) {
              await switchNetwork(
                metamaskProvider,
                responseData.data[0].chainId
              );
            }
            setWalletAddress(accounts[0]);
          }
        }
      }
    } else {
      toast.error(
        responseData.message ? responseData.message : "Something Wrong !!!"
      );
      setTimeout(() => {
        navigate("/");
      }, [2500]);
    }
  };
  useEffect(() => {
    checkIfWalletIsConnected();
  }, []);

  return (
    <MintContext.Provider
      value={{
        metamaskprovider,
        walletAddress,
        claimerDetails,
        setWalletAddress,
        ethersProvider,
      }}
    >
      {children}
      <ToastContainer />
    </MintContext.Provider>
  );
};
