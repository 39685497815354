import ReactGA from "react-ga4";

export const homeGAFunctions = {
  pressHomeEvent: function () {
    ReactGA.event({
      action: "Home",
      category: "Navbar",
    });
  },
  claimNFTEvent: function () {
    ReactGA.event({
      action: "Claim NFT",
      category: "Navbar",
    });
  },
  deployContractEvent: function () {
    ReactGA.event({
      action: "Deply Contract",
      category: "Navbar",
    });
  },
  connectWalletEvent: function () {
    ReactGA.event({
      action: "Connect Wallet",
      category: "Navbar",
    });
  },
};
