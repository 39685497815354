import { Network, Alchemy } from "alchemy-sdk";

export default async function GetAlchemySetting(chainID) {
  let NetworkType;
  if (chainID === "0x13881") {
    NetworkType = Network.MATIC_MUMBAI;
  }  else if (chainID === "0x5") {
    NetworkType = Network.ETH_GOERLI;
  } else if (chainID === "0x66eed") {
    NetworkType = Network.ARB_GOERLI;
  }
//   else if (chainID === "0x5a2") {
//     NetworkType = Network.POLYGONZKEVM_TESTNET;
//   }
  if (NetworkType) {
    return {
      apiKey: process.env.REACT_APP_ALCHEMY_KEY,
      network: NetworkType,
    };
  } else return null;
}
