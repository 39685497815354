import { useNavigate } from "react-router-dom";
import { appImages } from "../../constants/images";

function CollectionDisplayImages() {
  const collectionImagesArray = [];

  for (let i = 0; i < 5; i++) {
    collectionImagesArray.push(
      <>
        <span className="image-box">
          <img src={appImages.collection} alt="collection" />
        </span>
        <span className="image-box">
          <img src={appImages.dna} alt="dna" />
        </span>
      </>
    );
  }

  const collectionDisplayArray = [];
  for (let i = 0; i < 3; i++) {
    collectionDisplayArray.push(
      <div className="display-images-container">
        {collectionImagesArray && collectionImagesArray.length > 0
          ? collectionImagesArray.map((span) => span)
          : null}
      </div>
    );
  }

  return collectionDisplayArray && collectionDisplayArray.length
    ? collectionDisplayArray.map((item) => item)
    : null;
}

export function LaunchCollections() {
  const navigate = useNavigate();

  return (
    <div className="launch-collections content-components">
      <div className="animation-display-container">
        <CollectionDisplayImages />
      </div>
      <div className="gradient-container"></div>
      <div className="content-container">
        <h1 className="content-heading">Launch NFT project</h1>
        <div className="content-info">
          <p className="info">
            An entire NFT collection on <stong> ERC721A </stong> , with a mint
            page and gasless option
          </p>
          <button
            className="btn"
            onClick={() =>
              navigate("/create-contract", { state: "collection" })
            }
          >
            Create+
          </button>
        </div>
      </div>
    </div>
  );
}
