import { useNavigate } from "react-router-dom";
import { createAsGAFunctions } from "../../GA/CreateAsGA";

export function Frame() {
  const navigate = useNavigate();

  return (
    <>
      <div className="launch-game content-components">
        <div className="image-cards-container">
          <div className="image-cards">
            <span className="image-container bg"></span>
          </div>
          <div className="image-cards">
            <span className="image-container bg"></span>
          </div>
          <div className="image-cards">
            <span className="image-container"></span>
          </div>
          <div className="image-cards">
            <span className="image-container last-container"></span>
          </div>
        </div>
        <div className="content-container">
          <h1 className="content-heading">
            No-code NFT Mint on <strong>Farcaster</strong>
          </h1>
          <div className="content-info">
            <p className="info">Launch your own farcaster frame to allowing users to mint NFT</p>
            <button
              className="btn"
              onClick={() => {
                createAsGAFunctions.frameMintEvent();
                navigate("/frame-create");
              }}
            >
              Frame Mint
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
