export const Modal = ({ children, onCloseHandler }) => {
    const modalCloseHandler = (event) => {
        event.stopPropagation();
        onCloseHandler();
    };

    return (
        <>
            {children ? (
                <div className="modal-container">
                    <div className="modal-content-body">
                        <button
                            className="modal-close-btn"
                            onClick={modalCloseHandler}
                        >
                            <svg
                                width="17"
                                height="17"
                                viewBox="0 0 17 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <line
                                    x1="1.50578"
                                    y1="15.7116"
                                    x2="15.9245"
                                    y2="1.29292"
                                    stroke="black"
                                    strokeWidth="2"
                                />
                                <line
                                    x1="1.70711"
                                    y1="1.40971"
                                    x2="16.1258"
                                    y2="15.8284"
                                    stroke="black"
                                    strokeWidth="2"
                                />
                            </svg>
                        </button>
                        <div className="modal-content">{children}</div>
                    </div>
                </div>
            ) : null}
        </>
    );
};
