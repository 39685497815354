import { Modal } from "./Modal";

export const ModalOverlayContainer = () => {
    return (
        <>
            <div className="modal-overlay">
                <Modal />
            </div>
        </>
    );
};
