import airdropIcon from "../../../assets/airdrop-icon.png";
import { toast } from "react-toastify";

export const AirdropDetails = ({ setAirdropAddress, mintNFT,airdropAddress }) => {
  const formSubmitHandler = async (event) => {
    event.preventDefault();

    

    if (!airdropAddress) {
      toast.error("Please enter valid wallet address!");
      return;
    }
    await mintNFT();
  };
  return (
    <>
      <div className="airdrop-title-container">
        <span className="airdrop-icon">
          <img src={airdropIcon} alt="airdrop" />
        </span>
        <span className="airdrop-title">Airdrop</span>
      </div>
      <span className="airdrop-msg">
        Please Insert the wallet address to airdrop
      </span>
      <form className="airdrop-details-form" onSubmit={formSubmitHandler}>
        <div className="details-form-control-group">
          <label htmlFor="wallet-address" className="control-label">
            Wallet Address
          </label>
          <input
            type="text"
            name="walletAddress"
            id="wallet-address"
            placeholder="0x000...000"
            onChange={(e)=>setAirdropAddress(e.target.value)}
            required
            className="control-input"
          />
        </div>
        <button type="submit" className="form-submit-btn">
          Airdrop
        </button>
      </form>
    </>
  );
};
